import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Row, Col, Container } from 'react-bootstrap';

import Layout from '../components/layout';
import PostSEO from '../components/post-seo';
//import TagsList  from '../components/tags-list';

const BlogStyle = styled.div`
	margin-top: 0;
	padding-left: 1em;
	padding-right: 1em;

	p {
		font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
		font-size: 1.1rem;
		line-height: 1.5;
		font-weight: 400;
		padding-bottom: 1rem;
	}

	ul,
	li {
		font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
		font-size: 1.1rem;
		line-height: 1.2;
		font-weight: 400;
	}

	h1 {
		font-size: 3em;
		font-weight: 700;
		margin: 0.67em 0;
	}

	h2 {
		font-size: 2em;
		margin: 0.6em 0 0.33em;
	}
	h3 {
		font-size: 1.5em;
		margin: 0.6em 0 0.33em;
	}
	h4 {
		margin-bottom: 0.8rem;
	}

	h6 {
		font-size: 1.05em;
	}

	@media (max-width: 767px) {
		padding-left: 0;
		padding-right: 0;

		h1 {
			font-size: 1.8em;
		}
		h2 {
			font-size: 1.2em;
		}
	}
`;

const PostLayout = ({ data }) => {
	//const { markdownRemark } = data;
	const { markdown: { excerpt, html, frontmatter } } = data;
	const coverImage = frontmatter.image ? frontmatter.image.childImageSharp.resize : null;


	let imageObj = coverImage? {
		src: coverImage.src,
		width: coverImage.width.toString(),
		height: coverImage.height.toString()
	} : null;

	return (
		<Layout>
			<PostSEO title={frontmatter.title} description={excerpt} image={imageObj} />
			<Container>
				<Row className="justify-content-md-center">
					<Col lg={10}>
						<BlogStyle>
							<h1>{frontmatter.title}</h1>
							<Row>
								<Col md={3}> <h6>{frontmatter.date}</h6> </Col>
							{/* 	<Col><TagsList tags={frontmatter.tags} /></Col> */}
							</Row>
							
							
							<div
								dangerouslySetInnerHTML={{
									__html: html
								}}
							/>
						</BlogStyle>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export const query = graphql`
	query PostQuery($slug: String!) {
		markdown: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			html
			excerpt(pruneLength: 180)
			frontmatter {
				image: featuredImage {
					childImageSharp {
						resize(width: 900) {
							src
							height
							width
						}
					}
				}
				title
				tags
				date(formatString: "MMM DD, YYYY")
				slug
			}
		}
	}
`;

export default PostLayout;
